.admin-add-category-container {
    margin: auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .admin-add-category-container h2, h3 {
    text-align: left;
    color: black;
  }
  
  .admin-add-category-container .category-form {
    display: flex;
    gap: 10px;
  }
  
  .admin-add-category-container .category-form input {
    flex: 1;
    padding: 8px;
    color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .admin-add-category-container .category-form button {
    background: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .admin-add-category-container .category-list {
    list-style: none;
    padding: 0;
  }
  
  .admin-add-category-container .category-item {
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 10px;
    margin: 5px 0;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .admin-add-category-container .edit-btn {
    background: #ffc107;
    padding: 5px 10px;
    border: none;
    color: white;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .admin-add-category-container .delete-btn {
    background: #dc3545;
    padding: 5px 10px;
    border: none;
    color: white;
    cursor: pointer;
  }
  