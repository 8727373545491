/* Main container */
.contact-page-container {
  background: var(--bg);
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

/* Contact row with flexbox */
.contact-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  max-width: 1200px;
  width: 100%;
}

/* Image styling */
.contact-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.contact-image img:hover {
  transform: scale(1.05);
}

/* Form styling */
.contact-form {
  padding: 40px;
  max-width: 500px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.contact-form p {
  text-transform: uppercase;
  font-size: 14px;
  color: #444;
  margin-bottom: 10px;
}

.contact-form h2 {
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

.contact-form span {
  color: #777;
  margin-bottom: 20px;
  display: block;
}

/* Input, select, and textarea fields */
.contact-page-container input,
.contact-page-container select,
.contact-page-container textarea {
  width: 100%;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 15px;
  transition: border-color 0.3s ease;
}

.contact-page-container input:focus,
.contact-page-container select:focus,
.contact-page-container textarea:focus {
  border-color: #007bff;
  outline: none;
}

.contact-page-container input::placeholder,
.contact-page-container textarea::placeholder {
  color: #bbb;
}

/* Error message styling */
.error-text {
  color: #e74c3c;
  font-size: 12px;
}

/* Button styling */
.theme-btn {
  background-color: #000000;
  color: #fff;
  padding: 12px 0;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.theme-btn:hover {
  background-color: #f54355;
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-page-container {
    padding: 0px;
  }
  
  .contact-row {
    flex-direction: column;
    align-items: center;
  }

  .contact-image img {
    width: 60%;
  }

  .contact-form {
    padding: 30px;
    width: 90%;
  }
}
