/* Center the loader in the viewport */
.custom-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black; /* Black background */
}

/* Spinning red ring */
.custom-loader {
  width: 60px;
  height: 60px;
  border: 6px solid transparent;
  border-top: 6px solid red; /* Red top border */
  border-radius: 50%;
  animation: spinLoader 1s linear infinite;
  box-shadow: 0 0 15px red; /* Glowing red effect */
}

/* Spinning animation */
@keyframes spinLoader {
  0% {
    transform: rotate(0deg);
    box-shadow: 0 0 5px red;
  }
  50% {
    box-shadow: 0 0 15px red;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 0 0 5px red;
  }
}
