.login-container {
  background: var(--bg);
  padding: 80px 20px;
  min-height: 100vh;
}

.form-container {
  padding: 40px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.login-form h1 {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px;
}

.form-fields input {
  width: 100%;
  padding: 15px;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin-bottom: 20px;
  color: #333;
  font-size: 16px;
}

.form-fields input::placeholder {
  color: #aaa;
}

.login-btn {
  width: 100%;
  padding: 16px;
  background-color: var(--p);
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-btn:hover {
  opacity: 0.9;
}

.forgot-pass {
  font-size: 14px;
  color: #333;
}

.forgot-pass a {
  color: var(--p);
  text-decoration: underline;
}

.forgot-pass a:hover {
  color: var(--t);
}

@media screen and (max-width: 768px) {
  .form-container {
    padding: 30px 20px;
  }

  .login-form h1 {
    font-size: 24px;
  }

  .form-fields input {
    padding: 12px;
    font-size: 14px;
  }

  .login-btn {
    padding: 14px;
    font-size: 16px;
  }
}
