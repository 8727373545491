.leaderboard-container {
    background: var(--bg);
  }
.leaderboard-content {
    padding: 80px 20px;
    color: #262626;
    min-height: 100vh;
    max-width: 600px;
    margin: 0 auto;
  }
    .leaderboard-top-content {
        align-items: center;
        display: flex;
        justify-content: start;
        gap: 10px;
    }
    .leaderboard-top-content .box-1{
        align-items: center;
        border-radius: 15px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        background-image: linear-gradient(180deg, #fff, #fff);
    }
    .leaderboard-top-content h2{
        font-size: 18px;
        font-weight: 700;
        --bs-text-opacity: 1;
        letter-spacing: -.6px;
    }
  
  .leaderboard-container .heading {
    padding: 15px;
    font-size: 16px;
    text-align: center;
    border-radius: 10px;
    background-color: var(--t);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .leaderboard-container .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 8px;
    background: #581616;
    color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }
  
  .leaderboard-container .items:hover {
    transform: scale(1.03);
  }
  
  .leaderboard-container .items span {
    display: flex;
    align-items: center;
  }
  
  .leaderboard-container .items p {
    margin-right: 12px;
    font-weight: bold;
    background: #fff;
    color: #000;
    padding: 6px 12px;
    border-radius: 20px;
  }
  
  .leaderboard-container .top-1 {
    background: linear-gradient(135deg, #ffd700, #ffec8b);
  }
  .leaderboard-container .top-2 {
    background: linear-gradient(135deg, #c0c0c0, #dcdcdc);
  }
  .leaderboard-container .top-3 {
    background: linear-gradient(135deg, #cd7f32, #e07b39);
  }
  
  .leaderboard-container .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    border: 2px solid #fff;
  }
  
  .leaderboard-container .norecord {
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
    opacity: 0.8;
  }
  
  @media screen and (max-width: 768px) {
    .leaderboard-content {
      padding: 15px 15px;
    }
  }
  