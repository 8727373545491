.products-container {
  padding: 100px;
  border-top: 1px rgba(0, 0, 0, 0.3) !important;
  color: #000;
}
.product-title h2 {
  font-size: 50px;
  color: var(--p);
  font-weight: bold;
}
.product-title .icon {
  color: var(--p);
}
.products {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 30px;
}
.product {
  width: 12%;
  position: relative;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

/* 🎨 Hover Effect */
.product:hover {
  transform: translateY(-5px);
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.4);
}

/* 🔲 Product Image Styling */
.product-img-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
}

.product img {
  width: 100%;
  aspect-ratio: 1/1;
  transition: 0.2s all ease;
  object-fit: cover;
  border-radius: 10px;
  scale: 1;
}

/* 🖼️ Image Zoom on Hover */
.product:hover img {
  /* transform: scale(1.05); */
  width: 100%;
  aspect-ratio: 1/1;
  transition: 0.2s all ease;
  object-fit: cover;
  border-radius: 10px;
  scale: 1.05;
}

/* 📌 Product Name Styling */
.product-name {
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  margin-top: 12px;
  text-transform: capitalize;
}

/* 💲 Price Styling */
.d-price {
  font-size: 16px;
  font-weight: bold;
  color: #ffcc00;
  margin-top: 5px;
}

.o-price {
  font-size: 14px;
  text-decoration: line-through;
  color: rgba(255, 255, 255, 0.6);
}

/* 🔻 Responsive Adjustments */
@media screen and (max-width: 992px) {
  .product {
    width: 40% !important;
  }
}

@media screen and (max-width: 600px) {
  .product {
    width: 45% !important;
  }
}

@media screen and (max-width: 430px) {
  .product {
    width: 48% !important;
  }
}
 


.product-name {
  color: #000;
  text-align: center;
  font-weight: 500;
  margin-top: 5px;
  font-size: 14px;
  margin-top: 10px;
}
.product-name h6 {
  font-weight: bold;
  margin: 10px 0;
  display: block;
  word-break: break-word;
}
.d-price {
  font-size: 15px;
  font-weight: 600;
  margin-right: 4px;
}
.o-price {
  font-size: 14px;
  text-decoration: line-through !important;
  color: grey;
}

@media screen and (max-width: 992px) {
  .products-container {
    padding: 15px 15px;
    justify-content: flex-start;
  }
  .products {
    justify-content: space-evenly;
    gap: 0;
  }
  .product {
    width: 40% !important;
    padding: 10px;
    padding-bottom: 0;
  }
  .product-img-cont {
    width: 100%;
    position: relative;
  }
  .product .discount {
    bottom: 10px;
    left: 10px;
    padding: 0px 8px;
  }
  .product .discount span {
    font-size: 10px;
    font-weight: 800;
  }
  .product-name h6 {
    font-size: 14px;
  }
  .product-title h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 600px) {
  .product {
    width: 30% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  .product-name {
    font-size: 14px;
    font-weight: 600;
  }
  .product-title h2 {
    font-size: 30px;
  }
}

@media screen and (max-width: 430px) {
  .product {
    width: 30% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  .product-name {
    font-size: 12px;
    font-weight: 600;
  }
}

@media screen and (max-width: 430px) {
  .product {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 150px;
  }
}
