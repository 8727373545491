.main {
  padding: 20px;
}
.dashboard-layout {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.dash-left {
  width: 20%;
  display: flex;
  flex-direction: column;
  background-color: #eee;
  padding: 20px;
  min-height: 100vh;
}
.dash-menu .menu-item {
  margin: 20px 0;
  padding: 10px;
}
/* .active {
  background-color: white;
  border-radius: 5px;
} */
.dash-menu .menu-item a {
  margin-left: 10px;
  color: black;
}
.dash-right {
  width: 80%;
  padding: 20px;
  background-color: #f7f7f7;
}

.dashboard-container {
  padding: 30px 100px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: var(--bg);
}
.dashboard-menu {
  width: 30%;
  padding: 20px;
  background-color: var(--p);
  color: #fff;
}
.dashboard-menu ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboard-menu ul li {
  padding: 10px;
  padding-left: 10px;
  cursor: pointer;
  transition: 0.5s all ease;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.416);
}
.dashboard-menu ul li.active {
  background-color: var(--t);
  border-radius: 5px;
  color: #000;
}
.dashboard-menu ul li:hover {
  background-color: var(--t);
  color: #000;
}
.dashboard-menu ul li a {
  color: #ddd;
}

/* CONTENT  */
.dashboard-content {
  width: 70%;
  padding: 0 10px;
  padding-bottom: 50px;
}

.tp {
  position: relative;
}
.tp span {
  position: absolute;
  font-size: 10px;
  top: 0;
  color: red;
  font-weight: 500;
}

@media screen and (max-width: 992px) {
  .dash-right {
    width: 100%;
  }
  .mobile-menu {
  }
  .mobile-menu .menu-icon {
    font-size: 40px;
    color: #222f3e;
  }
  .mobile-menu-container {
    background-color: white;
    position: fixed;
    top: 80px;
    left: -100%;
    width: 100%;
    z-index: 500;
    height: 100vh;
    padding: 20px;
    transition: 0.3s all ease;
  }
  .mobile-menu-container.activeMenu {
    left: 0;
  }
  .mobile-menu-container .close-icon {
    margin-left: 10px;
    font-size: 35px;
    color: #333;
    cursor: pointer;
    border: 1px solid #333;
    margin-bottom: 30px;
  }
  .mobile-menu-container .menu-item,
  .mobile-menu-container a {
    color: #333;
    margin: 20px 10px;
  }

  .dashboard-container {
    padding: 0;
  }
  .dashboard-menu {
    width: 100%;
  }
  .dashboard-content {
    width: 100%;
    margin-top: 30px;
    overflow-x: scroll;
  }
}
